<template>
  <div class="services">
    <div class="container" v-if="!loading">
      <div class="card" v-if="!isMobile">
        <div class="card-header">
          <h5 style="font-size: 18px; font-weight: 700">
            {{ $t('settings.Services') }}
          </h5>
          <div class="card-header-right" style="right: 15px"><button class="btn btn-primary" @click="createGroup" style="width: 104px">{{ $t('settings.Create') }}</button></div>
        </div>
        <div class="card-body">
              <services-list v-if="!loading" :groups="groups"/>
        </div>
      </div>
      <div class="card" v-if="isMobile">
        <div class="card-header">
          <h5 style="font-size: 18px; font-weight: 700">
            {{ $t('settings.Base_groups') }}
          </h5>
        </div>
        <div style="display:flex; justify-content: center;"><button class="btn btn-primary" @click="createGroup">{{ $t('settings.Create') }}</button></div>
        <div class="card-body">
          <services-list v-if="!loading" :groups="groups"/>
        </div>
      </div>
      <div>
        <p class="m-b-10">{{ $t("service.without_groups") }}: <a v-b-modal.without_base class="btn-link">{{ meta.services.without_groups }} <i class="feather icon-link"></i></a></p>
        <p class="m-b-10">{{ $t("service.total_bases") }}: {{ meta.services.total }}</p>
        <p class="m-b-10">{{ $t("service.users_not_group") }}: <a v-b-modal.without_services class="btn-link">{{ meta.users.without_groups }} <i class="feather icon-link"></i></a></p>
        <p class="m-b-10">{{ $t("service.users_total") }}: {{ meta.users.total }}</p>
      </div>
    </div>
    <div v-else-if="loading" class="d-flex justify-content-center">
      <b-spinner/>
    </div>
    <b-modal id="without_base" :title="$t('services.services')" size="lg" hide-footer>
      <modal-services></modal-services>
    </b-modal>
    <b-modal id="without_services" :title="$t('settings.Users')" hide-footer>
        <modal-users></modal-users>
    </b-modal>
  </div>

</template>

<script>
import {mapGetters} from "vuex";
import ServicesList from "@/components/baseServices/servicesList";
import ModalServices from "@/components/baseServices/ModalServices";
import ModalUsers from "@/components/baseServices/ModalUsers";
import ModalSources from "@/components/baseGroups/ModalSources";

export default {
  name: "Services",
  components: {ModalUsers, ModalServices, ServicesList},
  data() {
    return {
      loading: false,
      isMobile: false
    }
  },
  computed: {
    ...mapGetters('service_groups', ['groups', 'meta']),
  }, 
  methods: {
    getList() {
      this.loading = true;
      this.$store.dispatch('service_groups/getGroupList', {}).then(() => {
        this.loading = false;
      })
    },
    createGroup(){
      this.$router.push({name : "ServiceCreate"});
    },

  },
  mounted() {
    this.getList();
    this.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent);
  }
}
</script>

<style lang="scss" scoped>


@media (max-width: 992px) {
  .btn{
    padding-left: 8px;
    padding-right: 8px;
    width: calc(100% - 16px);
  }
  .card-header{
    display: flex;
    justify-content: center;
    h5:after{
      display: none;
    }
  }
}

.is-floating {
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px $primary;
  border-radius: 50%;
  position: fixed;
  bottom: 50px;
  height: 60px;
  width: 60px;
}
@media screen and (max-width: 768px)  {
  .is-floating {
    right: 50px;
  }
}
.user-wrapper {
  position: relative;
}
a {
  color: #1c4ecf;
  text-decoration: underline;
  &.black {
    color: #000;
    text-decoration: none;
  }
}
</style>