<template>
  <div>
    <div v-if="!isLoading">
      <ul v-if="typeof services !== 'undefined' && services.length" class="list-group">
        <li
            v-for="service in services"
            :key="service.id"
            class="list-group-item"
        >
          {{service.name}}
        </li>
      </ul>
    </div>
    <div v-else>
      <b-skeleton width="85%"></b-skeleton>
      <b-skeleton width="55%"></b-skeleton>
      <b-skeleton width="70%"></b-skeleton>
      <b-skeleton width="85%"></b-skeleton>
      <b-skeleton width="55%"></b-skeleton>
      <b-skeleton width="70%"></b-skeleton>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "ModalServices",
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters('service_groups', ['services']),
  },
  methods: {
    loadServices() {
      this.isLoading = true;
      this.$store.dispatch('service_groups/getServices',{
        filters: {
          has_service_group: false,
        },
      }).finally(() => {
        console.log(this.services)
        this.isLoading = false;
      });
    }
  },
  mounted() {
    this.$store.dispatch('source_groups/clearSources');
    this.loadServices();
  }
}
</script>

<style scoped>

</style>