<template>
  <div>
    <div v-if="!isLoading">
      <div v-if="typeof users !== 'undefined' && users.length" class="list-group">
        <router-link v-for="user in users" :key="user.id" :to="{name: 'UserEdit', params: {id: user.id}}" class="list-group-item">
          {{user.login}} ({{user.first_name}} {{user.last_name}})
        </router-link>
      </div>
    </div>
    <div v-else>
      <b-skeleton width="85%"></b-skeleton>
      <b-skeleton width="55%"></b-skeleton>
      <b-skeleton width="70%"></b-skeleton>
      <b-skeleton width="85%"></b-skeleton>
      <b-skeleton width="55%"></b-skeleton>
      <b-skeleton width="70%"></b-skeleton>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "ModalUsers",
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters('users', ['users']),
  },
  methods: {
    loadUsers() {
      this.isLoading = true;
      this.$store.dispatch('users/getUsersList',{
        filters: {
          has_service_group: false,
          parent_or_self: JSON.parse(localStorage.getItem('user')).id
        },
      }).finally(() => {
        this.isLoading = false;
      });
    }
  },
  mounted() {
    this.$store.dispatch('users/clearUsersList');
    this.loadUsers();
  }
}
</script>

<style scoped>

</style>